.statusIcon {
    margin: 10px;
    text-align: center;
    width: 100px;
}

.box {
    border: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: gray;
    padding: 10px;
    margin: 1px;
}

